export default defineNuxtPlugin((nuxtApp) => {
  useHead({
    script: [
      // Other scripts
      {
        src: 'https://aggle.net/js?publisher=herb.co&pid=DRVICTVF',
        type: 'text/javascript'
      },
      {
        src: 'https://tags.herb.co?appId=10f65dd0-35e0-4664-ac2b-2ff2e4277aa7&environment=thirdparty&segmentId=VeGIfiJZsNmZ2l76u3hXSA&version=2',
        type: 'text/javascript'
      },
      // Adelphic Universal Pixel definition
      {
        src: 'https://js.ipredictive.com/adelphic_universal_pixel.js',
        type: 'text/javascript',
        onload: () => {
          console.log('Adelphic Universal Pixel loaded')
          // Once the script is loaded, instantiate the pixel
          if (window.AdelphicUniversalPixel) {
            new AdelphicUniversalPixel(113285, 'https://ad.ipredictive.com/d/track/event', { ps: '0' }).fire()
          }
        }
      }
    ]
  })
})
